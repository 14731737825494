import React, {} from 'react';
import { Helmet } from 'react-helmet';

import * as style from 'styles/components/utils/consentModal.module.scss';

const ConsentModal = ({ show, children, className, containerStyle }) => {

    if (!show) {
        return null;
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <div
                className={`${style.modal__overlay} ${containerStyle || ''}`}
                role="dialog"
                aria-modal="true"
            >

                {/* Used to remove the scroll of the body when the modal is open */}
                <Helmet>
                    <body data-modal-open="true" />
                </Helmet>

                {/* eslint-disable-next-line */}
                <div
                    className={`${style.modal} bg ${className}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default ConsentModal;
