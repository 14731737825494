import { useStaticQuery, graphql } from 'gatsby';

const useAccountInfoBoxes = () => {
    const {
        allSanityInfoBox: { nodes: infoBoxes },
    } = useStaticQuery(graphql`
        query accountInfoBoxes {
            allSanityInfoBox {
                nodes {
                    _id
                    boxContent {
                        en {
                            _rawContent
                        }
                        se {
                            _rawContent
                        }
                    }
                    pages {
                        slug {
                            current
                        }
                    }
                }
            }
        }
    `);

    return infoBoxes;
};

export default useAccountInfoBoxes;
