import React, { useContext, useState } from 'react';

import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const ProfileConnectedInput = ({
    fieldName,
    labelKey,
    value,
    notConnectedValue,
    manageUrl,
    connectRoute,
    isEmail = false,
}) => {
    const { lang } = useContext(LangContext);

    const { updateUser } = useAuth();

    const [isConnected, setIsConnected] = useState(isEmail || (value && value.length));
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState(null);

    const removeAccount = (event) => {
        event.preventDefault();

        updateUser(fieldName, '').then((err) => {
            if (err) {
                setError(err);
            } else {
                setError(null);
                setIsConnected(false);
                setInputValue('');
            }
        });
    };

    return (
        <div className="form-group">
            <label htmlFor={fieldName}>
                <FormattedMessage id={labelKey} />
            </label>

            <div className={`input-group connect ${isConnected ? "connected-account" : ""}`}>
                <input type="text" id={fieldName} name={fieldName} readOnly
                       value={inputValue || notConnectedValue}
                       disabled={!isConnected}
                />

                <div className="input-group-button">
                    {isConnected && (
                        <>
                            <a href={manageUrl} target="_blank" rel="noreferrer"
                               className="cta-yellow cta-external" aria-label={getFormattedMessage('form.manage', lang)}>
                                <FormattedMessage id="form.manage" />
                            </a>

                            {!isEmail && (
                                <button type="button" className="cta-yellow-ter cta-internal" onClick={removeAccount} aria-label={getFormattedMessage('account.axs.changeAccount', lang)}>
                                    <FormattedMessage id="account.axs.changeAccount" />
                                </button>
                            )}
                        </>
                    )}

                    {!isConnected && (
                        <>
                            <i className="ico-warning" aria-hidden />
                            <CustomLink namedRoute={connectRoute} className="cta-yellow">
                                <FormattedMessage id="form.connect" />
                            </CustomLink>
                        </>
                    )}
                </div>
            </div>

            {error && (
                <span className="input-group-error">
                    <FormattedMessage id="form.errorGlobal" />
                </span>
            )}
        </div>
    );
};

export default ProfileConnectedInput;
