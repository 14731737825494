import React from 'react';

import AccountLayout from 'components/account/Layout';
import ProfileForm from 'components/account/profile/ProfileForm';

const AccountProfile = ({ pageContext }) =>
    <AccountLayout pageContext={pageContext}>
        <ProfileForm />
    </AccountLayout>;

export default AccountProfile;
