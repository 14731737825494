import React, { useContext, useState } from 'react';

import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import * as style from 'styles/components/utils/consent.module.scss';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import Loader from 'components/utils/Loader';

const Consent = () =>  {
    const { lang } = useContext(LangContext);
    const { user, updateUser } = useAuth();

    const [formError, setFormError] = useState(null);
    const [loading, setLoading] = useState(false);

    const putUserTeam = () => {
        const requestUrl = process.env.GATSBY_API_TEAM_RELATION;
        const requestHeaders = new Headers();
        requestHeaders.append('Authorization', user?.signInUserSession.idToken.jwtToken);
        requestHeaders.append('Content-Type', 'application/json');
        const requestParams = {
            method: 'PUT',
            headers: requestHeaders,
        };

        fetch(requestUrl, requestParams)
            .then((response) => {
                if (response.status === 202) {
                    // Handle a successful 202 response
                    window.location.reload();
                } else {
                    // Handle other responses (e.g., error responses)
                    setFormError("An error occurred.");
                    setLoading(false);
                }
            })
            .catch((error) => {
                // setLoading(false);
                console.error('Error:', error);
                setFormError("An error occurred.");
                setLoading(false);
            });
    };

    const handleFormSubmit = async (evt) => {
        evt.preventDefault();
        setLoading(true);

        const formData = new FormData(evt.target);
        const promises = [];

        formData.forEach((value, key) => {
            promises.push(updateUser(key, value));
        });

        try {
            const results = await Promise.all(promises);
            const hasError = results.some((err) => err !== null);

            if (hasError) {
                setLoading(false);

                setFormError("An error occurred.");
            } else {
                // window.location.reload();
                putUserTeam();
            }
        } catch (error) {
            setFormError(error.message);
        }
    };
    return (
        <div className={style.consent__wrapper}>
            <h2 className="title-xs-mobile title-sm text-semibold">{getFormattedMessage('account.consent.welcome', lang)}</h2>
            {loading &&
                <Loader message={getFormattedMessage('account.consent.saving', lang)} />
            }
            {!loading &&
                <>
                <div className="richtext">
                    <p>
                        {getFormattedMessage('account.consent.getStarted', lang)}
                    </p>
                </div>
                <form method="POST" onSubmit={handleFormSubmit} className={style.consent__form}>

                    <div className="form-group checkbox">
                        <input type="checkbox" name="custom:consent_membership" id="custom:consent_membership" required />
                        <label htmlFor="custom:consent_membership">
                            Jag godkänner Hammarbys  <a href="/allmana-villkor" target="_blank" rel="noreferrer">allmäna villkor</a> och <a href="/integritetspolicy" target="_blank" rel="noreferrer">integritetspolicy</a>
                            <em>*</em>
                        </label>
                    </div>
                    <div className="form-group checkbox">
                        <input type="checkbox" name="custom:consent_marketing" id="custom:consent_marketing"  />
                        <label htmlFor="custom:consent_marketing">
                            {getFormattedMessage('account.consent.marketing', lang)}
                        </label>
                    </div>
                    {formError && (
                        <span className="input-group-error">
                            <FormattedMessage id="form.errorGlobal" />
                        </span>
                    )}
                    <div className="form-submit">
                        <button className="cta-yellow cta-external" type="submit">
                            {getFormattedMessage('account.consent.continue', lang)}
                        </button>
                    </div>
                </form>
                </>
            }
        </div>
    );
};

export default Consent;
