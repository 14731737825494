import { useStaticQuery, graphql } from 'gatsby';

const useAccountConfig = () => {
    const { sanityConfigAccount: accountConfig } = useStaticQuery(graphql`
        query {
            sanityConfigAccount {
                accountProfileContent {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
            }
        }
    `);

    return accountConfig;
};

export default useAccountConfig;
