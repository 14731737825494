import React, { useContext, useState } from 'react';

import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const ProfileFormInput = ({
    field,
    fieldType,
    labelKey,
    baseValue = '',
    required = false,
    pattern = '*',
    prefixValue = null,
}) => {
    const { lang } = useContext(LangContext);
    const { updateUser } = useAuth();

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(baseValue);
    const [inputTmpValue, setInputTmpValue] = useState(inputValue);
    const [error, setError] = useState(null);

    const handleChange = (event) => {
        setInputTmpValue(event.target.value);
    };

    const handleValidate = (event) => {
        event.preventDefault();

        updateUser(field, inputTmpValue).then((err) => {
            if (err) {
                setError(err);
            } else {
                setInputValue(inputTmpValue);
                setIsEditing(false);
                setError(null);
            }
        });
    };

    const handleEdit = () => {
        setIsEditing(true);
        if (prefixValue && inputTmpValue === '') {
            setInputTmpValue(prefixValue);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setError(null);
        setInputTmpValue(inputValue);
    };

    return (
        <form method="POST" onSubmit={handleValidate}>
            <div className="form-group">
                <label htmlFor={field}>
                    <FormattedMessage id={labelKey} />
                    {required && <em>*</em>}
                </label>

                <div className="input-group">
                    <input type={fieldType} id={field} name={field}
                           placeholder={getFormattedMessage(labelKey, lang)}
                           readOnly={!isEditing}
                           required={required}
                           pattern={pattern}
                           value={inputTmpValue} onChange={handleChange}
                           onClick={() => !isEditing && handleEdit()}
                    />

                    <div className="input-group-button">
                        {!isEditing && (
                            <button type="button" className="cta-ico"
                                    aria-label={getFormattedMessage('form.edit', lang)}
                                    onClick={handleEdit}>
                                <i className="ico-edit" aria-hidden />
                            </button>
                        )}

                        {isEditing && (
                            <>
                                <button type="submit" className="cta-ico"
                                        aria-label={getFormattedMessage('form.validate', lang)}>
                                    <i className="ico-check" aria-hidden />
                                </button>

                                <button type="button" className="cta-ico"
                                        aria-label={getFormattedMessage('form.cancel', lang)}
                                        onClick={handleCancel}>
                                    <i className="ico-cross" aria-hidden />
                                </button>
                            </>
                        )}
                    </div>

                    {error && (
                        <span className="input-group-error">
                            {error.toString().includes('Invalid phone number format')
                                ? <FormattedMessage id="form.errorInvalidPhone" />
                                : <FormattedMessage id="form.errorGlobal" />
                            }
                        </span>
                    )}
                </div>
            </div>
        </form>
    );
};

export default ProfileFormInput;
